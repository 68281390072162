import {
  Quotes as QuotesUI,
  IQuoteProps as IQuotesUIProps,
} from "@business-finland/wif-ui-lib/dist/Quotes";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { getAssetPath, jcrMultiFieldToArray } from "../../../util/magnolia";

interface IQuoteProps {
  text: string;
  referenceText?: string;
  video: Wif.Mgnl.Video;
  image?: Wif.Mgnl.Image;
  iconAltText?: string;
}

interface IQuotesProps {
  quotes: Wif.Mgnl.NodeWithList<Wif.Mgnl.Node<IQuoteProps>>;
}

export function Quotes(props: IQuotesProps): JSX.Element {
  const { isPreview } = useRouter();
  const quotes = useMemo(
    () =>
      jcrMultiFieldToArray(props.quotes).map((quote) =>
        mapQuoteNodeToQuoteUI(quote, isPreview)
      ),
    [props.quotes, isPreview]
  );

  return (
    <div>
      <QuotesUI quotes={quotes} />
    </div>
  );
}

function mapQuoteNodeToQuoteUI(
  quote: Wif.Mgnl.Node<IQuoteProps>,
  isPreview = false
): IQuotesUIProps {
  return {
    id: quote["@id"],
    text: quote.text,
    referenceText: quote.referenceText,
    iconAltText: quote.iconAltText,
    iconVideoSrc: getAssetPath(quote.video, isPreview),
    iconFallbackImgSrc: getAssetPath(quote.image, isPreview),
  };
}
